































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsContributionsInitialContributionViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-contributions-initial-contribution-view-model';

@Component({ name: 'ContractSavingsContributionsInitialContribution' })
export default class ContractSavingsContributionsInitialContribution extends Vue {
  contract_savings_initial_contribution = Vue.observable(
    new ContractSavingsContributionsInitialContributionViewModel(),
  );

  @Watch('contract_savings_initial_contribution.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async nextStep() {
    const save_step = await this.contract_savings_initial_contribution.saveStep();
    if (save_step) {
      this.$emit('nextStep');
    }
  }

  created() {
    this.contract_savings_initial_contribution.small_screen = getScreenSizeVariant() === 'small';
    this.contract_savings_initial_contribution.initialize();
  }
}

